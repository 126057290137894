import React, { ReactComponentElement } from "react";
import { useLocation } from "react-router";

type Props = {
  children: ReactComponentElement<any>;
};
const AuthLayout = ({ children }: Props) => {
  const { pathname } = useLocation();

  return (
    <div className="w-full min-h-screen bg-[#F5F5F5] flex justify-center items-center relative">
      <div className="w-full">{children}</div>
      <div className="absolute w-full left-0 bottom-[20px] flex items-center justify-center">
        <p
          className={`text-[12px] text-[#667085] font-medium ${
            pathname === "/auth/register" && "hidden"
          }`}
        >
          {" "}
          Nurovant © {`${new Date().getFullYear()}`}
        </p>
      </div>
    </div>
  );
};

export default AuthLayout;
