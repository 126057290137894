import React, { useMemo } from "react";
import Logo from "./Logo";
import { LuClipboardList, LuLogOut, LuUser } from "react-icons/lu";
import { IoPlayBackOutline, IoPlayForwardOutline } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BiHomeAlt2 } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { Avatar, Button, Divider, Menu, Image, Drawer } from "antd";
import { isEqual } from "../context/utils";
import authAtom from "../atoms/auth/auth.atom";
import { useRecoilValue } from "recoil";
import { extractAvatar } from "../context/utils";
import { FaPlus } from "react-icons/fa";
import HomeIcon from "../assets/HomeIcon.svg";

type Props = {
  isMenuOpen: boolean;
  onMenuClose: any;
  isOpen: boolean;
  onClose: any;
  onOpen: any;
};
function SideBar({ isOpen, onOpen, onClose, isMenuOpen, onMenuClose }: Props) {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const items = [
    {
      key: "/",
      label: "Home",
      icon: <img alt="logo" src={HomeIcon} />,
    },
    // {
    //   key: "/respondents",
    //   label: "Respondents",
    //   icon: <LuUser className='!text-lg !font-medium' />,
    // },
    // {
    //   key: "/results-database",
    //   label: "Results Database",
    //   icon: <LuClipboardList className='!text-lg !font-medium' />,
    // },
  ];

  const extras = useMemo(
    () => [
      {
        key: "hide",
        label: isOpen ? "Open" : "Hide",
        icon: !isOpen ? (
          <IoPlayBackOutline
            onClick={onOpen}
            className="!text-lg !font-medium"
          />
        ) : (
          <IoPlayForwardOutline
            onClick={onClose}
            className="!text-lg !font-medium"
          />
        ),
      },
      {
        key: "/settings",
        label: "Settings",
        icon: <BsGear className="!text-lg !font-medium" />,
      },
    ],
    [isOpen, onClose, onOpen]
  );

  const { user } = useRecoilValue(authAtom);

  const handleMenu = ({ key }: any) => {
    if (!isEqual(key, "hide")) {
      onMenuClose();
      navigate(key);
      if (isOpen) {
        onClose();
      }
    }
    // return onOpen();
  };
  const handleLogout = () => navigate("/auth/logout");

  const MenuContent = () => (
    <div
      className={`"w-full h-full flex flex-col justify-between items-center ${
        !isOpen && "px-3"
      } py-10 ${pathname === "/info" && "hidden"}`}
    >
      <div className="w-full flex flex-col justify-center items-center gap-10">
        <Logo isIcon={isOpen} />
        <Menu
          style={{
            border: 0,
            color: "#4970FC",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "700",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
          }}
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={[pathname]}
          inlineCollapsed={isOpen}
          className="space-y-3 !bg-[#dbe2fe] "
          onClick={handleMenu}
          key="main-menu"
          mode="inline"
          items={items}
        />
      </div>
      <div className="w-full flex flex-col justify-center items-center">
        <Menu
          style={{
            border: 0,
            background: "transparent",
            color: "#646462",
            fontSize: "16px",
            fontWeight: "500",
          }}
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={[pathname]}
          inlineCollapsed={isOpen}
          className="space-y-3"
          onClick={handleMenu}
          key="extra-menu"
          items={extras}
          mode="inline"
        />
        <Divider />
        <div
          className={`w-full flex ${
            isOpen ? "justify-center" : "justify-between"
          } items-center`}
        >
          <div className="flex items-center gap-3">
            <Avatar alt="user" size="large">
              {(user?.user?.name !== null || user?.user?.name === undefined) &&
                extractAvatar(user?.user?.name)}
            </Avatar>
            <p
              hidden={isOpen}
              className="text-primary text-[12px] w-[100px] truncate"
            >
              {user?.user?.email}
            </p>
          </div>
          <Button
            hidden={isOpen}
            onClick={handleLogout}
            className="text-primary !p-0 !m-0"
            type="text"
            icon={<LuLogOut className="!text-lg !font-medium" />}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className="w-full h-full">
      <div className="hidden md:block w-full h-full ">
        <MenuContent />
      </div>

      <Drawer
        onClose={onMenuClose}
        open={isMenuOpen}
        closeIcon={false}
        placement="left"
        footer={false}
        width={250}
      >
        <MenuContent />
      </Drawer>
    </div>
  );
}

export default SideBar;
