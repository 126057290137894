import { useRecoilState, useRecoilValue } from "recoil";
import React, { ReactComponentElement, useState } from "react";
import SideBar from "../../components/SideBar";
import menuAtom from "../../atoms/menu/menu.atom";
import { HiOutlineBell } from "react-icons/hi";
import { ModalContainer } from "../../components/ModalContainer";
import { useLocation, useNavigate } from "react-router";
import authAtom from "../../atoms/auth/auth.atom";
import { Button } from "antd";
import { LuLogOut, LuMenu } from "react-icons/lu";
import { routes } from "../../router";
import { isEqual } from "../../context/utils";
import { useGetProfile } from "../../hooks/profile/profile";

type Props = {
  children: ReactComponentElement<any>;
};
const MainLayout = ({ children }: Props) => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useRecoilState(menuAtom);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onMenuClose = () => setIsMenuOpen(false);
  const onMenuOpen = () => setIsMenuOpen(true);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const route = routes?.find((d) => isEqual(d?.path, pathname));
  useGetProfile();
  return (
    <ModalContainer>
      <div className="w-full h-screen max-h-screen flex flex-col md:flex-row justify-between items-center md:justify-start p-0 m-0 md:bg-light font-montserrat overflow-y-hidden !relative">
        <div className="w-full bg-white flex gap-2 items-center md:relative md:hidden pt-5 px-5">
          <Button
            icon={<LuMenu className="!text-2xl !font-medium !bg-transparent" />}
            className="text-[#101828] !p-0 !m-0"
            onClick={onMenuOpen}
            type="text"
          />
          <p className="text-xl font-bold">{route?.label}</p>
        </div>
        {/* sidebar component can come in here  */}
        <div
          className={`w-0 h-0 ${
            isOpen ? "md:!w-[5%]" : "md:!w-[20%]"
          } md:h-full ${pathname === "/info" && "hidden"}`}
        >
          <SideBar
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isMenuOpen={isMenuOpen}
            onMenuClose={onMenuClose}
          />
        </div>

        <div
          className={`w-full h-full ${
            isOpen ? "md:!w-[95%]" : "md:!w-[80%]"
          } overflow-y-hidden`}
        >
          {/* main layout pages children  */}
          <div
            className={`w-full h-full bg-white ${
              pathname !== "/info" && " overflow-y-auto p-5 md:p-0"
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default MainLayout;
